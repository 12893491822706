import { HOST_PLAYER_ID } from "@shared/src/gc/constants";
import { assert } from "../../logger/assert";
import GameEntry from "../GameEntry";
import GameManager, { GameManagerEvents } from "../GameManager";
import { GameEntryData } from "../GameEntry";
import { HostClient } from "./HostClient";

export type HostGameManagerEvents = GameManagerEvents & {
  setup_game: (data: { gameEntry: GameEntryData }) => void;
  unload_game: () => void;
  setup_complete: () => void;
  waiting_game_setup: (data: { waitingClientIds: number[]; doneClientIds: number[] }) => void;
};

class HostGameManager extends GameManager<HostGameManagerEvents> {
  protected client: HostClient | null = null;
  private waitingClientIds: number[] = [];
  private doneClientIds: number[] = [];

  protected debug(...args: unknown[]) {
    console.debug("HostGameManager -", ...args);
  }

  setup(client: HostClient): void {
    this.client = client;
  }

  getClient() {
    assert(this.client, "Client not set");
    return this.client;
  }

  setupGame(gameEntry: GameEntry, initialPlayerIds: number[]) {
    assert(!this.gameEntry, "Game already set up. Unload previous game first.");

    const hostClient = this.getClient();

    this.waitingClientIds = [HOST_PLAYER_ID, ...initialPlayerIds];
    this.doneClientIds = [];

    const dispatchUpdate = () => {
      this.dispatch("waiting_game_setup", {
        waitingClientIds: this.waitingClientIds,
        doneClientIds: this.doneClientIds,
      });
    };

    const handlePlayerJoinOrReconnect = (playerId: number) => {
      if (!this.waitingClientIds.includes(playerId)) {
        this.waitingClientIds.push(playerId);
      }
      if (this.doneClientIds.includes(playerId)) {
        this.doneClientIds = this.doneClientIds.filter((id) => id !== playerId);
      }

      sendSetupGameEventToPlayer(playerId);
      dispatchUpdate();
    };

    const playerJoinedEventId = hostClient.on("party_player_joined", (data) => {
      this.debug("Player joined during setup", data.playerId);
      handlePlayerJoinOrReconnect(data.playerId);
    });

    const playerLeftEventId = hostClient.on("party_player_left", (data) => {
      this.debug("Player left during setup", data.playerId);
      removeClientFromWaiting(data.playerId);
    });

    const playerReconnectedEventId = hostClient.on("player_reconnected", (data) => {
      this.debug("Player reconnected during setup", data.playerId);
      handlePlayerJoinOrReconnect(data.playerId);
    });

    const hostReadyEventId = hostClient.frameEvents.on("setup_game_frame_done", () => {
      this.debug("Host setup done");
      setClientAsLoaded(HOST_PLAYER_ID);
    });

    const playerReadyEventId = hostClient.playerClientEvents.on("setup_game_frame_done", (playerId) => {
      this.debug("Player setup done", playerId);
      setClientAsLoaded(playerId);
    });

    const sendSetupGameEventToPlayer = (playerId: number) => {
      hostClient.sendEventToPlayerClient(playerId, "setup_game", { gameEntry: gameEntry.getData() });
    };

    this.gameEntry = gameEntry;
    for (const playerId of initialPlayerIds) {
      sendSetupGameEventToPlayer(playerId);
    }

    const completeGameSetup = () => {
      this.debug("Game setup complete called");
      hostClient.frameEvents.off(hostReadyEventId);
      hostClient.playerClientEvents.off(playerReadyEventId);
      hostClient.off(playerJoinedEventId);
      hostClient.off(playerReconnectedEventId);
      hostClient.off(playerLeftEventId);
      this.dispatch("setup_complete");
    };

    const removeClientFromWaiting = (playerId: number) => {
      this.waitingClientIds = this.waitingClientIds.filter((id) => id !== playerId);
      dispatchUpdate();
    };

    const setClientAsLoaded = (playerId: number) => {
      this.debug("Setting client as loaded", playerId);
      this.waitingClientIds = this.waitingClientIds.filter((id) => id !== playerId);
      assert(!this.doneClientIds.includes(playerId), "Client already marked as done");
      this.doneClientIds.push(playerId);
      dispatchUpdate();
    };

    this.dispatch("setup_game", { gameEntry: gameEntry.getData() });
    dispatchUpdate();

    return {
      completeGameSetup,
      getWaitingClientIds: () => {
        return this.waitingClientIds;
      },
      getDoneClientIds: () => {
        return this.doneClientIds;
      },
    };
  }

  unloadGame() {
    assert(this.gameEntry, "No game to unload");

    this.getClient().sendEventToAllPlayerClients("unload_game");

    this.gameEntry = null;
    this.dispatch("unload_game");
  }

  getGameEntry(): GameEntry | null {
    return this.gameEntry;
  }
}

export default HostGameManager;
