import { Link } from "react-router-dom";
import HostClient from "../../../../common/client/host/HostClient";

import HeaderPlayers from "./players/HeaderPlayers";

const LobbyHeader = () => {
  const partyId = HostClient.getPartyId(true);

  return (
    <div className="relative flex max-h-[204px] min-h-[204px] w-full items-center justify-start bg-indigo-950">
      <div className="w-[242px] px-10">
        <Link to="/host">
          <img className="pointer-events-none h-[105px] select-none" src="/gc-logo.svg" alt="" />
        </Link>
      </div>
      <div className="h-full flex-1 items-center justify-center px-5">
        <HeaderPlayers />
      </div>
      <div className="flex w-[242px] flex-col justify-center px-6 text-center font-bold">
        <span className="block text-2xl">Party ID</span>
        <span className="block text-4xl">{partyId}</span>
      </div>
    </div>
  );
};

export default LobbyHeader;
